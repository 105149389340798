import InstallmentScheduleTimeGeneralModel from "./InstallmentScheduleTimeGeneralModel";
import InstallmentScheduleTime from "./InstallmentScheduleTime";
import InstallmentScheduleTimesFilter from "./InstallmentScheduleTimesFilter";

export default class InstallmentScheduleTimes extends InstallmentScheduleTimeGeneralModel {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setGeneralModelInitialValue();
    this.installmentScheduleTimesData = [];
    this.installmentScheduleTime = new InstallmentScheduleTime();
    this.filterData = new InstallmentScheduleTimesFilter();
  }
  fillData(data) {
    if (data) {
      this.fillGeneralModelData(data);
      this.installmentScheduleTimesData =
        data.installmentScheduleTimesData ?? [];
      let filter = { ...this.filterData, ...data.pagination };
      this.filterData.fillData(filter);
    } else {
      this.setInitialValue();
    }
  }
}
