<template>
  <div>
    <PreLoader v-if="isLoading" />

    <CustomBottomSheet
      refName="InstallmentScheduleTimeAdd"
      size="xl"
      :headerText="
        debtTokenOfUser ? $t('Expenses.pay') : $t('Installments.add')
      "
      :headerIcon="installmentScheduleTime.icon"
      :clickToClose="false"
      :swipeAble="false"
      @opened="bottomSheetOpened()"
    >
      <InstallmentScheduleTimeForm
        v-if="!isLoading"
        id="add"
        :installmentScheduleTime="installmentScheduleTime"
        :installmentController="installmentController"
        :showInstallmentBatchTypes="showInstallmentBatchTypes"
        v-on:submitForm="addInstallmentScheduleTime()"
        bottomSheetName="InstallmentScheduleTimeAdd"
        :submitName="$t('add')"
      />
    </CustomBottomSheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import InstallmentScheduleTimeForm from "./InstallmentScheduleTimeForm.vue";
import { STATUS } from "./../../../../utils/constants";
// import { objectToFormData } from "./../../../../utils/functions";
import apiInstallmentScheduleTime from "./../../../../api/joiningApplications/installmentScheduleTimes";
import generalMixin from "./../../../../utils/generalMixin";
import { SCHEDULING_STATUS_TOKENS } from "./../../../../utils/constantLists";
import { getAllDebtsCustomized } from "./../../../../utils/dialogsOfApi";
import InstallmentController from "./../../../../models/joiningApplications/installmentScheduleTimes/InstallmentController";
import {
  // getInstallmentBatchTypesDialog,
  getAccountsDialog,
  getPaymentMethodsDialog,
} from "./../../../../utils/dialogsOfApi";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomBottomSheet,
    InstallmentScheduleTimeForm,
  },
  props: ["installmentScheduleTime", "userTokenOfDebt", "debtTokenOfUser"],
  data() {
    return {
      installmentController: new InstallmentController(),
      accountTokenOptions: [],
      paymentMethodTokenOptions: [],
      // showInstallmentBatchTypes: true,
    };
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
    showInstallmentBatchTypes() {
      return this.debtTokenOfUser ? false : true;
    },
  },
  async created() {},
  methods: {
    async bottomSheetOpened() {
      this.installmentScheduleTime.setInitialValue();
      if (this.debtTokenOfUser) {
        await this.getAccountsDialog();
        await this.getPaymentMethodsDialog();
      }
      await this.getDebtsDialog();
    },
    async getAccountsDialog() {
      this.isLoading = true;
      this.accountTokenOptions = await getAccountsDialog();
      this.isLoading = false;
    },
    async getPaymentMethodsDialog() {
      this.isLoading = true;
      this.paymentMethodTokenOptions = await getPaymentMethodsDialog();
      this.isLoading = false;
    },
    async getDebtsDialog() {
      this.isLoading = true;
      let params = {
        userToken: this.userTokenOfDebt,
        schedulingStatusTypeToken: SCHEDULING_STATUS_TOKENS.NotHaveSchedule,
      };
      let [userAllDebtsData, error] = await getAllDebtsCustomized(params);
      if (!error) {
        if (!this.debtTokenOfUser) {
          let userInfoData = userAllDebtsData[0].userInfoData;

          let installmentsData = await userAllDebtsData.map((d, i) => {
            let installmentScheduleTimesDataObj = {
              installmentMoney: d.debtMoneyStatistics.debtMoney,
              installmentMoneyWithCurrency:
                d.debtMoneyStatistics.debtMoneyWithCurrency,
              debtToken: d.debtToken,
              maxVal: d.debtMoneyStatistics.debtMoney,
              remainderMoney: d.debtMoneyStatistics.debtMoney,
              installmentScheduleTimePaidMoney:
                d.debtMoneyStatistics.installmentScheduleTimePaidMoney,
            };

            return {
              debtToken: d.debtToken,
              fullCode: d.fullCode,
              debtTitleCurrent: d.debtTitleCurrent,
              installmentMoney: d.debtMoneyStatistics.debtMoney,
              installmentMoneyWithCurrency:
                d.debtMoneyStatistics.debtMoneyWithCurrency,
              maxVal: d.debtMoneyStatistics.debtMoney,
              remainderMoney: d.debtMoneyStatistics.debtMoney,
              installmentScheduleTimePaidMoney:
                d.debtMoneyStatistics.installmentScheduleTimePaidMoney,
              isSelected: true,
              number: 1,
              index: i,
              posion: i + 1,
              installmentScheduleTimesData: [installmentScheduleTimesDataObj],
            };
          });

          let debtInstallmentsHandler = [];
          installmentsData.forEach((element) => {
            debtInstallmentsHandler.push(element);
            this.installmentScheduleTime.fillAddData({
              userInfoData: userInfoData,
              installmentsData: [element],
            });
          });

          this.installmentController.fillData({
            userInfoData: userInfoData,
            debtInstallmentsHandler: debtInstallmentsHandler,
            installmentsData: debtInstallmentsHandler,
          });
        } else {
          userAllDebtsData = userAllDebtsData.filter(
            (i) => i.debtToken == this.debtTokenOfUser
          );

          let userInfoData = userAllDebtsData[0].userInfoData;

          let installmentsData = await userAllDebtsData.map((d, i) => {
            let installmentScheduleTimesDataObj = {
              installmentMoney: d.debtMoneyStatistics.debtMoney,
              installmentMoneyWithCurrency:
                d.debtMoneyStatistics.debtMoneyWithCurrency,
              debtToken: d.debtToken,
              maxVal: d.debtMoneyStatistics.debtMoney,
              remainderMoney: 0,
              installmentScheduleTimePaidMoney: d.debtMoneyStatistics.debtMoney,
              accountToken: this.accountTokenOptions.length
                ? this.accountTokenOptions[0].value
                : "",
              paymentMethodToken: this.paymentMethodTokenOptions.length
                ? this.paymentMethodTokenOptions[0].value
                : "",
              installmentDateTime: new Date().toISOString(),
            };

            return {
              debtToken: d.debtToken,
              fullCode: d.fullCode,
              debtTitleCurrent: d.debtTitleCurrent,
              installmentMoney: d.debtMoneyStatistics.debtMoney,
              installmentMoneyWithCurrency:
                d.debtMoneyStatistics.debtMoneyWithCurrency,
              maxVal: d.debtMoneyStatistics.debtMoney,
              remainderMoney: 0,
              installmentScheduleTimePaidMoney: d.debtMoneyStatistics.debtMoney,
              isSelected: true,
              number: 1,
              index: i,
              posion: i + 1,
              installmentScheduleTimesData: [installmentScheduleTimesDataObj],
            };
          });

          let debtInstallmentsHandler = [];
          installmentsData.forEach((element) => {
            console.log(element);
            debtInstallmentsHandler.push(element);
            this.installmentScheduleTime.fillAddData({
              userInfoData: userInfoData,
              installmentsData: [element],
            });
          });

          this.installmentController.fillData({
            userInfoData: userInfoData,
            debtInstallmentsHandler: debtInstallmentsHandler,
            installmentsData: debtInstallmentsHandler,
          });
        }
      }
      this.isLoading = false;
    },

    async addInstallmentScheduleTime() {
      this.isLoading = true;
      let installmentsData =
        await this.installmentController.installmentsData.map((element) => {
          return {
            debtToken: element.debtToken,
            installmentScheduleTimesData:
              element.installmentScheduleTimesData.map((i) => {
                return {
                  installmentMoney: i.installmentMoney,
                  installmentDateTime: i.installmentDateTime,
                  installmentScheduleTimeNotes: i.installmentScheduleTimeNotes,
                  installmentBatchTypeToken: i.installmentBatchTypeToken,
                  installmentScheduleTimePaidMoney:
                    i.installmentScheduleTimePaidMoney,
                  accountToken: i.accountToken,
                  paymentMethodToken: i.paymentMethodToken,
                };
              }),
          };
        });
      let modelAdd = await {
        userAuthorizeToken: this.userAuthorizeToken,
        installmentsData: installmentsData,
      };

      // let formData = objectToFormData(modelAdd);
      try {
        const response = await apiInstallmentScheduleTime.add(modelAdd);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$emit("refresh");
          this.closeBottomSheet("InstallmentScheduleTimeAdd");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>
