<template>
  <div class="">
    <DashboardNavbar
      :btnAddName="'InstallmentScheduleTimeAdd'"
      :btnAddStatus="
        checkPrivilege(hasInstallmentScheduleTimeAdd()) && !hasData
      "
      :filterSheetName="'filterSheet'"
      :filterSheetStatus="false"
      v-on:showStatistics="showStatistics = !showStatistics"
      :viewShowStatistics="hasData"
    />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <!-- <DebtTabs /> -->
      <!-- debt info -->
      <div class="statistics" v-if="hasDebtData">
        <div class="my-card no-margin">
          <span class="my-card-title">{{ $t("Debts.data") }}</span>

          <table class="my-table">
            <thead>
              <tr>
                <th>{{ $t("general.code") }}</th>
                <th>{{ $t("general.value") }}</th>
                <th>{{ $t("general.paid") }}</th>
                <th>{{ $t("general.remainding") }}</th>
                <th>{{ $t("general.type") }}</th>
                <th>{{ $t("Debts.schedulingStatusType") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ isDataExist(debt.fullCode) }}
                </td>

                <td>
                  {{
                    isDataExist(debt.debtMoneyStatistics.debtMoneyWithCurrency)
                  }}
                </td>
                <td>
                  {{
                    isDataExist(debt.debtMoneyStatistics.paidMoneyWithCurrency)
                  }}
                </td>
                <td>
                  {{
                    isDataExist(
                      debt.debtMoneyStatistics.remainderMoneyWithCurrency
                    )
                  }}
                </td>
                <td>
                  {{ isDataExist(debt.debtTypeNameCurrent) }}
                </td>
                <td>
                  {{
                    isDataExist(
                      debt.schedulingData.schedulingStatusTypeNameCurrent
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <template v-if="hasData">
        <div
          v-if="filterInstallmentScheduleTimeStatistics && showStatistics"
          class="statistics"
        >
          <b-button v-b-toggle.statistics class="btn btn-lg btn-collapse">
            {{ $t("Installments.statistics.modelName") }}
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </b-button>
          <b-collapse id="statistics">
            <div class="row">
              <div
                v-for="(
                  statistic, index
                ) in filterInstallmentScheduleTimeStatistics"
                :key="index"
                class="col-md-4"
              >
                <StatisticsCard
                  :title="statistic.title"
                  :totalNumber="statistic.value"
                  :icon="statistic.icon"
                  :showPercentage="statistic.showPercentage"
                  :percentage="statistic.percentage"
                />
              </div>
            </div>
          </b-collapse>
        </div>

        <!-- table -->
        <InstallmentScheduleTimeTable
          v-on:setInstallmentScheduleTimeData="
            installmentScheduleTimes.installmentScheduleTime.fillData($event)
          "
          v-on:addInstallmentPayment="addInstallmentPayment($event)"
          v-on:refundInstallmentPayment="refundInstallmentPayment($event)"
          :installmentScheduleTimesData="
            installmentScheduleTimes.installmentScheduleTimesData
          "
          :defaultImg="
            installmentScheduleTimes.installmentScheduleTime.defaultImg
          "
          :filterData="installmentScheduleTimes.filterData"
        />
        <ActionsData
          :actionsData="installmentScheduleTimes.installmentScheduleTime"
        />
      </template>
      <ExceptionWithImg v-else :msg="exceptionMsg" :image="exceptionImg" />
      <CustomPagination
        v-if="hasData"
        :paginationData="installmentScheduleTimes.filterData"
        v-on:changePagination="changePagination($event)"
      />
      <!-- <InstallmentScheduleTimeFilter
        :theFilterData="installmentScheduleTimes.filterData"
        v-on:search="search($event)"
      /> -->
      <InstallmentScheduleTimeAdd
        :installmentScheduleTime="
          installmentScheduleTimes.installmentScheduleTime
        "
        :userTokenOfDebt="userTokenOfDebt"
        v-on:refresh="getAllInstallmentScheduleTimes()"
      />
      <InstallmentPaymentAdd
        :installmentPayment="installmentPayments.installmentPayment"
        v-on:refresh="getAllInstallmentScheduleTimes()"
      />
      <template>
        <div>
          <CustomBottomSheet
            refName="InstallmentPaymentTableWithActions"
            size="xl"
            :headerText="$t('InstallmentPayments.data')"
            :headerIcon="installmentPayments.installmentPayment.icon"
          >
            <InstallmentPaymentTable
              v-on:setInstallmentPaymentData="
                installmentPayments.installmentPayment.fillData($event)
              "
              :installmentPaymentsData="
                installmentPayments.installmentPaymentsData
              "
              :defaultImg="installmentPayments.installmentPayment.defaultImg"
              :filterData="installmentPayments.filterData"
            />
          </CustomBottomSheet>
        </div>
        <!-- closeBottomSheet('InstallmentPaymentTableWithActions'); -->
        <InstallmentPaymentRefund
          :installmentPayment="installmentPayments.installmentPayment"
          v-on:refresh="getAllInstallmentScheduleTimes()"
        />
        <ActionsData :actionsData="installmentPayments.installmentPayment" />
        <InstallmentPaymentInfo
          :installmentPayment="installmentPayments.installmentPayment"
        />
        <CustomBottomSheetQRCode
          :refName="'InstallmentPaymentQRCode'"
          :code="installmentPayments.installmentPayment.fullCode"
          :codeTitle="$t('InstallmentPayments.code')"
          :name="
            installmentPayments.installmentPayment
              .installmentPaymentDescriptionCurrent
          "
          :nameTitle="$t('InstallmentPayments.description')"
          :nameIcon="'installmentPayments.svg'"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { STATUS, VIEW_TYPES } from "./../../../utils/constants";
import DashboardNavbar from "./../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../components/general/PreLoader.vue";
import ActionsData from "./../../../components/general/ActionsData.vue";
import CustomBottomSheetQRCode from "./../../../components/general/CustomBottomSheetQRCode.vue";
import StatisticsCard from "./../../../components/general/StatisticsCard.vue";
import ExceptionWithImg from "./../../../components/general/ExceptionWithImg.vue";
import CustomPagination from "./../../../components/general/CustomPagination.vue";
import CustomBottomSheet from "./../../../components/general/CustomBottomSheet.vue";
import InstallmentScheduleTimeTable from "./components/InstallmentScheduleTimeTable.vue";
// import DebtTabs from "./../debts/components/DebtTabs.vue";
import InstallmentPaymentAdd from "./../installmentPayments/components/InstallmentPaymentAdd.vue";
// import InstallmentScheduleTimeFilter from "./components/InstallmentScheduleTimeFilter.vue";
import InstallmentScheduleTimeAdd from "./components/InstallmentScheduleTimeAdd.vue";
import InstallmentScheduleTimes from "./../../../models/joiningApplications/installmentScheduleTimes/InstallmentScheduleTimes";
import apiInstallmentScheduleTime from "./../../../api/joiningApplications/installmentScheduleTimes";
// import { getDialogOfActivationTypes } from "./../../../utils/dialogsOfConstantsLists";
import generalMixin from "./../../../utils/generalMixin";
import generalSettingsMixin from "./../../../utils/generalSettingsMixin";
import { checkPrivilege, isDataExist } from "./../../../utils/functions";
import { hasInstallmentScheduleTimeAdd } from "./../../../utils/privilegeHelper";
import InstallmentPayments from "./../../../models/joiningApplications/installmentPayments/InstallmentPayments";
import InstallmentPaymentTable from "././../../../views/joiningApplications/installmentPayments/components/InstallmentPaymentTable.vue";
import InstallmentPaymentRefund from "././../../../views/joiningApplications/installmentPayments/components/InstallmentPaymentRefund.vue";
import InstallmentPaymentInfo from "././../../../views/joiningApplications/installmentPayments/components/InstallmentPaymentInfo.vue";
import apiInstallmentPayment from "./../../../api/joiningApplications/installmentPayments";
import Debt from "./../../../models/joiningApplications/debts/Debt";
import apiDebt from "./../../../api/joiningApplications/debts";

export default {
  mixins: [generalMixin, generalSettingsMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    ActionsData,
    CustomBottomSheetQRCode,
    StatisticsCard,
    ExceptionWithImg,
    CustomPagination,
    CustomBottomSheet,
    InstallmentScheduleTimeTable,
    // DebtTabs,
    InstallmentPaymentAdd,
    InstallmentScheduleTimeAdd,
    InstallmentPaymentTable,
    InstallmentPaymentRefund,
    InstallmentPaymentInfo,
  },
  props: {
    userToken: {
      type: String,
      default: "",
    },
    debtToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      installmentScheduleTimes: new InstallmentScheduleTimes(),
      VIEW_TYPES,
      filterInstallmentScheduleTimeStatistics: [],
      installmentPayments: new InstallmentPayments(),
      showStatistics: false,
      debt: new Debt(),
    };
  },
  computed: {
    hasData() {
      return (
        this.installmentScheduleTimes.installmentScheduleTimesData.length != 0
      );
    },
    hasDebtData() {
      return this.debt.debtToken != "";
    },
    userTokenOfDebt() {
      return this.debt.userToken ? this.debt.userToken : this.userToken;
    },
    hasDataPayments() {
      return this.installmentPayments.installmentPaymentsData.length != 0;
    },
  },
  watch: {
    debtToken: function (val) {
      if (val) this.getDebtDetails();
    },
    hasDataPayments: function (val) {
      if (val) this.openBottomSheet("InstallmentPaymentTableWithActions");
    },
  },
  async created() {
    this.installmentScheduleTimes.filterData.userToken = this.userToken;
    this.installmentScheduleTimes.filterData.debtToken = this.debtToken;
    await this.getAllInstallmentScheduleTimes();
    await this.getDebtDetails();
  },
  methods: {
    async getDebtDetails() {
      this.isLoading = true;
      try {
        this.debt.setInitialValue();
        const response = await apiDebt.getDetails({ token: this.debtToken });
        if (response.data.status == STATUS.SUCCESS) {
          this.debt.fillData(response.data.debt);
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.showMsg(response.data.msg);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.showMsg(response.data.msg);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    getClass(installment) {
      let className = "";
      // paid all
      if (installment.remainderMoney == 0) {
        className = "status-green";
      } else if (installment.remainderMoney > 0) {
        className = "status-yellow";
      }
      return className;
    },
    checkPrivilege,
    isDataExist,
    hasInstallmentScheduleTimeAdd,
    addInstallmentPayment(scheduleTime) {
      this.installmentPayments.installmentPayment.installmentScheduleTimeInfo =
        scheduleTime;
      this.installmentPayments.installmentPayment.installmentScheduleTimeToken =
        scheduleTime.installmentScheduleTimeToken;
      this.installmentPayments.installmentPayment.installmentPaymentMoney =
        scheduleTime.remainderMoney;
      this.installmentPayments.installmentPayment.remainderMoney =
        scheduleTime.remainderMoney;
      this.openBottomSheet("InstallmentPaymentAdd");
    },
    async refundInstallmentPayment(scheduleTime) {
      // console.log(scheduleTime.installmentScheduleTimeToken);
      await this.getAllInstallmentPayments(
        scheduleTime.installmentScheduleTimeToken
      );
    },
    async getAllInstallmentPayments(token) {
      this.isLoading = true;
      try {
        this.installmentPayments.filterData.installmentScheduleTimeToken =
          token;
        this.installmentPayments.filterData.paginationStatus = false;
        this.installmentPayments.installmentPaymentsData = [];
        const response = await apiInstallmentPayment.getAll(
          this.installmentPayments.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.installmentPayments.fillData(response.data);
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.showMsg(response.data.msg);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.showMsg(response.data.msg);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    setFilteredStatistics() {
      let statistic =
        this.installmentScheduleTimes.installmentScheduleTimeStatistics;

      this.filterInstallmentScheduleTimeStatistics = [
        {
          title: this.$t(
            "Installments.statistics.totalScheduleInstallmentMoney"
          ),
          value: statistic.totalInstallmentsMoneyWithCurrency,
          icon: "money.svg",
          showPercentage: false,
          percentage: {
            value: 0,
            title: "",
          },
        },
        {
          title: this.$t("Installments.statistics.totalScheduleIPaidMoney"),
          value: statistic.totalPaidMoneyWithCurrency,
          icon: "money.svg",
          showPercentage: true,
          percentage: {
            value: statistic.paidPercentage,
            title: statistic.paidPercentageText,
          },
        },
        {
          title: this.$t("Installments.statistics.totalScheduleRemainderMoney"),
          value: statistic.totalRemainderMoneyWithCurrency,
          icon: "money.svg",
          showPercentage: true,
          percentage: {
            value: statistic.remainderPercentage,
            title: statistic.remainderPercentageText,
          },
        },
      ];
    },
    changePagination(pagination) {
      this.installmentScheduleTimes.filterData.fillData(pagination);
      this.getAllInstallmentScheduleTimes();
    },

    search(data) {
      this.installmentScheduleTimes.filterData.fillData(data);
      this.getAllInstallmentScheduleTimes();
    },
    async getAllInstallmentScheduleTimes() {
      this.isLoading = true;
      try {
        this.installmentScheduleTimes.installmentScheduleTimesData = [];
        const response = await apiInstallmentScheduleTime.getAll(
          this.installmentScheduleTimes.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.installmentScheduleTimes.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      await this.setFilteredStatistics();
      this.isLoading = false;
    },
  },
};
</script>
