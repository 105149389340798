import { METHOD, request } from "./../../utils/request";
import { InstallmentScheduleTimes } from "./../api";

async function getAll(params) {
  return request(InstallmentScheduleTimes.getAll, METHOD.GET, params);
}
async function getDetails(params) {
  return request(InstallmentScheduleTimes.getDetails, METHOD.GET, params);
}
async function getDialog(params) {
  return request(InstallmentScheduleTimes.getDialog, METHOD.GET, params);
}

async function add(data) {
  return request(InstallmentScheduleTimes.add, METHOD.POST, data);
}

export default {
  getAll,
  getDetails,
  getDialog,
  add,
};
